import axios from "axios";

const API_URL = process.env.REACT_APP_BASE_URL_API;
const token = `Bearer ` + localStorage.getItem('token');
const createdBy = localStorage.getItem('userId');
const updatedBy = localStorage.getItem('userId');
const mailRoomId = localStorage.getItem('mailRoomId');
const branch = (localStorage.userDetails) ? JSON.parse(localStorage.userDetails).branch : "";
const tCode = Date.now().toString();
const config = {
  headers: {
    'Authorization': token,
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
  }
};

const getoutwardLogById = async (id:Number) => {
  return await axios.get(API_URL + "outwardlog/" + id, config).then(
    (response) => {
      return response.data.message;
    }
  )
}
const outwardlogCreate = async ( modeName:Number,outwardDate:string, costCenter:Number, refNo:string,noOfShipment:Number,transferType:string,pickupStaff:string,remarks:string,employeeName:String, employeeTableId:Number,employeeID:string,) => {
  const bodyParamter = {
    modeName,outwardDate,costCenter,refNo,noOfShipment,transferType,pickupStaff,remarks,employeeName,employeeTableId,
    employeeId: employeeID, tCode, mailRoomId, branch , createdBy, outwardlogStatus : 1
  };
  return axios.post(API_URL + "outwardlog/register", bodyParamter, config).then(
      (response) => {
        if (response.data.statuscode === "200") {    
          return response.data.message
        }
      })
      .catch(error => console.log(error))

}

// -------------outwardlogUpdate------------------------------------------------------------------------------------------------------
const outwardlogUpdate = async (modeName:Number,outwardDate:string,  costCenter:Number, refNo:string,noOfShipment:Number,transferType:string,pickupStaff:string,remarks:string,employeeName:String, employeeTableId:Number,employeeID:string, id:any) => {
  const bodyParamter = {
    modeName,outwardDate,costCenter,refNo,noOfShipment,transferType,pickupStaff,remarks,employeeName,employeeTableId,
    employeeId: employeeID, tCode, mailRoomId, branch , updatedBy
  };
  return axios.put(API_URL + "outwardlog/"+id, bodyParamter, config).then(
      (response) => {
        if (response.data.statuscode === "200") {    
          return response.data.message
        }
      })
      .catch(error => console.log(error))

}


const getOutwardLogListdateWise = async (logDateSearch :string) => {
  const bodyParamter = {
    mailRoomId,
    branch,
    outwardDate : logDateSearch
  };
  return await axios.post(API_URL + "outwardlog/", bodyParamter, config).then(
    (response) => {
      if (response.data.statuscode === "200") {     
        return response.data.message
      }
    }
  ).catch(error => console.log(error))
}
const getOutwardLogPendingList = async () => {
  const bodyParamter = {
    mailRoomId,
    branch
  };
  return await axios.post(API_URL + "outwardlog/pendingoutwarddeliveries", bodyParamter, config).then(
    (response) => {
      if (response.data.statuscode === "200") {       
        return response.data.message
      }
    }
  ).catch(error => console.log(error))
}
const getOutwardLogList = async () => {
  const bodyParamter = {
    mailRoomId,
    branch
  };
  return await axios.post(API_URL + "outwardlog/", bodyParamter, config).then(
    (response) => {
      if (response.data.statuscode === "200") {      
        return response.data.message
      }
    }
  ).catch(error => console.log(error))
}
// change by nk
const getDrs = async (drsNumber: any) => {
  return axios.get(API_URL + "drs/", config).then(
    (response) => {
      return response.data.message

    }
  )
    .catch(error => console.log(error))

}
const getDrsId = async (id: any) => {

  return axios.get(API_URL + "/drs/" + id, config).then(
    (response) => {
      //  console.log( response.data.message)
      return response.data.message

    }
  )
    .catch(error => console.log(error))

}
// post outward status


const postupdateoutwarddlog = async (id:any) => {
  const bodyParamter = {

    // outwarddetailStatus: outwarddetailStatus,
  
  };
 
  return axios
    .post(API_URL + "outwardlog/"+id, bodyParamter, config).then(
      (response) => {

        return response.data;
      })
    .catch(error => console.log(error));

}
const PostOutwardMail = async (title: any,
  signature: any,
  receiver: any,
  subject: any,
  content: any,
  to: any,
  fromDate: any,
  toDate: any,



) => {
  const bodyParamter = {

    title: title,
    // "branch": 2,
    // "receiver": "Sanyog Softwares",
    // "subject": "Daily MIS Report Test Mail",
    // "content": "Attaching herewith excel file of auto generated mis report for branch no. 2.",
    // "signature": "Regards,\nSanyog Softwares,\nPune - 412015.",



    fromDate: fromDate,
    toDate: toDate,
    to: to,

   
    receiver: receiver,
    subject: subject,
    content: content,
    signature: signature


  };
  return axios
    .post(API_URL + "/misreport/getreportbymail", bodyParamter, config).then(
      (response) => {

        return response.data;
      })
    .catch(error => console.log(error));

}

const deleteoutwardLog = (id:Number) => {
  return axios.delete(API_URL + "outwardlog/"+id,  config).then(
    (response) => {
        return response.data.message
    })
    .catch(error => console.log(error))

}
const postupdateoutwarddetailstatus = async (
  outwarddetailid:any,
  outwarddetailStatus: any,
  receivedBy: String,
  remark: String,
  outwardDate: any, outwardTime: any,  deliverySign: any,  ReasonId :Number, ReasonLabel : any, manifestId :Number) => {
  const bodyParamter = {
    manifestId ,
    id:outwarddetailid,
    outwarddetailStatus: outwarddetailStatus,
    receivedBy: receivedBy,
    remark: remark,
    deliveryDateTime: outwardDate+' '+outwardTime,
    reasonId : ReasonId,
    missRouteReason : ReasonLabel,
    updatedBy ,
    createdBy,  
    mailRoomId, branch,  
    deliverySign: deliverySign
  };
  // console.log(bodyParamter)
  // console.log(deliverySign);
  return axios
    .post(API_URL + "outwarddetail/updatemultipleoutwardstatus/", bodyParamter, config).then(
      (response) => {
    if(response.data.statuscode ==='200'){
        return response.data.message;
      }
      })
    .catch(error => console.log(error));

}

 const trackOnline = (outwarddetailid:any,vendorId:any) => {
  const bodyParamter = { 
    id:outwarddetailid,
    mailRoomId,
    vendorId,
    branch
  }
  return axios
   // .post(API_URL + "tracking/bluedart", bodyParamter, config).then(
    .post(API_URL + "tracking/vendortracking", bodyParamter, config).then(
      (response) => {
    if(response.data.statuscode ==='200'){
        return response.data.message;
      }
      })
    .catch(error => console.log(error));
 }


const OutwardLogService = {
  getoutwardLogById,
  outwardlogCreate,
  outwardlogUpdate,
  deleteoutwardLog,
  getOutwardLogList,
  getOutwardLogPendingList,
  getOutwardLogListdateWise,
  getDrs,
  getDrsId,
  postupdateoutwarddlog,
  PostOutwardMail,
  postupdateoutwarddetailstatus,
  trackOnline
}

export default OutwardLogService;