import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import Swal from 'sweetalert2'
import moment from 'moment';
import OutwardLogService from '../../services/outwardlog.service';
import Outcontainerheader from './Outcontainerheader';
import OutwardDetailService from '../../services/outwarddetail.sercice';

function OutwardDetailsList() {


  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRecords, setSelectedRecords] = useState();
  const [outwardDate, setoutwardDate] = useState((moment(new Date())).format('YYYY-MM-DD'));
  const [allOutwardDetails, setAllOutwardDetails] = useState([]);
  const [selectedAWBNumber, setselectedAWBNumber] = useState('');
  const addButtonRef = useRef<any>(null); 
   const[totalInwards, setTotalInwards] = useState(0)
  const[totalPcs, setTotalPcs] = useState(0)
  const[totalPending, setTotalPending] = useState(0)
  const[totalDelivered, setTotalDelivered] = useState(0)

  interface selectRowType {
    mode: any;
    clickToSelect: boolean;  
    style: any;
    onSelect: any;
  }

  const selectRow: selectRowType = {
    mode: "radio",
    clickToSelect: true,
    style: { backgroundColor: '#c8e6c9' },
    onSelect: (row: any) => {
      setSelectedRecords(row.id);
      setselectedAWBNumber(row.AWB);
    }
  }
  interface Article {
    options: any,
    currSizePerPage: any,
    onSizePerPageChange: any,
  }
  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }: Article) => (
    <div className="btn-group" role="group">
      {
        options.map((option: any) => {
          const isSelect = currSizePerPage === `${option.page}`;
          return (
            <button
              key={option.text}
              type="button"
              onClick={() => onSizePerPageChange(option.page)}
              className={`btn ${isSelect ? 'btn-secondary' : 'btn-red text-white'}`}
            >
              {option.text}
            </button>
          );
        })
      }
    </div>
  );

  interface optionsType {
    sizePerPageRenderer: any
  }
  const options: optionsType = {
    sizePerPageRenderer
  };
  const dateformater = (data: any, row: any) => {
    return  (moment(data)).format('DD/MM/YYYY')
  }
  const pagination = paginationFactory(options);
  const columns: ColumnDescription<selectRowType>[] = [
    { dataField: 'id', formatter: (cell, row, rowIndex, formatExtraData) => {
      return rowIndex + 1;
    }, text: 'Sr No',  align: 'center'},
    { dataField: 'outwardDate', text: 'Out Date', sort: true,  align: 'left', //formatter:dateformater 

    },
    { dataField: 'osrNumber', text: 'OSR Number', align: 'left'},
    { dataField: 'employeeName', text: 'Employee Name',  align: 'left' },   
    { dataField: 'consigneeName', text: 'Consignee Name', align: 'left' },
    { dataField: 'consigneeZipCode', text: 'Detination', align: 'left' },
    { dataField: 'serviceProviderName', text: 'Service Provider', align: 'left' },
    { dataField: 'shipmentModeName', text: 'Mode Name', headerAlign: 'left', align: 'left'},
    { dataField: 'AWB', text: 'AWB Number', align: 'left' },
    { dataField: 'outwarddetailStatusDescription', text: 'Status', align: 'left' },
   
    { dataField: 'remarks', text: 'Remark', align: 'left' , 
    formatter: (cell, row :any) => {
      let remarksLabel = "";

      if (row.receivedBy !== null) {
        remarksLabel = row.receivedBy;
      }
      
      if (row.remark !== null) {
        remarksLabel = remarksLabel + ' ' + row.remark;
      }
  
      return <div>{remarksLabel}</div>;
    }},
    { dataField: 'username', text: 'Entry By'},
  ];
  //Table End
  const getOutwardLogList = async () => {
    await OutwardDetailService.getOutwardDetailsListdateWise(outwardDate).then(
      (data) => {
        if (data !== "") {
          setAllOutwardDetails(data);
        } else {
          setAllOutwardDetails([]);
        }
      }
    );
  }
  useEffect(() => {
    getOutwardLogList();
  }, [outwardDate])
  useEffect(() => {
    setoutwardDate((moment(new Date())).format('YYYY-MM-DD'))
    addButtonRef.current.focus();
  }, [])

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })

  const HandleDelete = () => {
    if (selectedRecords !== undefined) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          OutwardDetailService.deleteoutward(selectedRecords)
            .catch((error) => {
              console.log(error);
              console.log("server responding");

            })
          Swal.fire({
            title: "Deleted",
            icon: 'success',
            text: "Outward Entry has been deleted",
          }).then(function () {
            window.location.reload()
          })
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            {
              title: "Cancelled",
              icon: 'error',
              text: "Outward Log is safe :)",
            }).then(function () {
              window.location.reload()
            }
            )
        }
      })
    }
  }

  const navigate = useNavigate();
  const EditDelivery = () => {
    if (selectedRecords !== undefined) {
      navigate('../outworddetails', { state: selectedRecords });
    }
  }

  const filteredData = Array.isArray(allOutwardDetails)
    ? allOutwardDetails.filter((selectedRecords) => {
      const values = Object.values(selectedRecords);
      return values.some(
        (value: unknown): boolean => {
          if (typeof value === 'string' || typeof value === 'number') {
            const stringValue = String(value).toLowerCase();
            return stringValue.includes(searchQuery.toLowerCase());
          }
          return false;
        }
      );
    })
    : [];
    
    useEffect(() => {
      setTotalInwards(filteredData.length)
      const sum = filteredData.reduce((acc, item:any) => acc + (parseInt(item.numberOfPieces,10) || 0), 0);
      setTotalPcs(sum);
      const pendingSum = filteredData
      .filter((item:any) => item.outwarddetailStatus === 0) // Filter by status
      .reduce((acc, item:any) => acc + (parseInt(item.numberOfPieces,10) || 0), 0);
    setTotalPending(pendingSum); // Set the sum of pending items
    
    const deliveredSum = filteredData
    .filter((item:any) => item.outwarddetailStatus === 8) // Filter by status
    .reduce((acc, item:any) => acc + (parseInt(item.numberOfPieces, 10) || 0), 0);
    setTotalDelivered(deliveredSum); // Set the sum of pending items
    
    }, [filteredData]); // Run effect when `filteredData` changes 

    const handleTracking = () => {
      if (selectedRecords !== undefined) {
        navigate('../outwardtracking', { state: selectedAWBNumber });
      } 
    }
  return (
    <>
      <Outcontainerheader />
      <div style={{ margin: "0px" }}>
        <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}>
          <div className="container col-11" style={{paddingLeft : '0px'}}>
            <div className="themeColor" style={{ width: '100%' }} ><p style={{ marginLeft: "10px" }}> Outward Details List</p></div>

            <div className='col-12' style={{ display: "flex", flexDirection: "row", width: "100%", height: "50px", justifyContent: "space-evenly", paddingTop: '1%' }}>

              <div className='margincard1itams' style={{ marginLeft: "10px" }}>
                <label> Out Date</label>
                <input className='margininutleft' type='Date' value={outwardDate} max={moment().format("YYYY-MM-DD")} name="logDateSearch" onChange={(e) => { setoutwardDate(e.target.value) }} />
              </div>
              <div className='margincard1itams'>
                <label >Branch Name :  {(localStorage.userDetails) ? JSON.parse(localStorage.userDetails).branchName : ""} </label>
              </div>
              <div className='col-6' style={{ display: "flex", height: "50px", justifyContent: "end" }}>
                <input
                  className='searchbar col-6'
                  type='text'
                  value={searchQuery}
                  placeholder='Search Here ...'
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
            <div className="table-responsive tableContainer">
            <BootstrapTable rowStyle={{ fontSize: '15px', padding: '0px', whiteSpace: 'nowrap', width: '100%'}}
              keyField='id'
              striped
              hover
              condensed
              // data={allServiceProvider}
              data={filteredData}
              columns={columns}
              // pagination={pagination}
              selectRow={selectRow}
              filter={filterFactory()}
              filterPosition="top"
              
            />
           </div>
          </div>
          <div className='row col-12 d-flex justify-content-center'>
          <div className='col-5' style={{fontWeight :'bold'}}>
            <span className='col-1'> Outward : <span style={{color: 'blue'}}>{totalInwards} </span> </span>
            <span className='col-1' > Pcs. : <span style={{color: 'blue'}}>{totalPcs}</span></span>
            <span className='col-1'> Deliverd: <span style={{color: 'green'}}>{totalDelivered}</span></span>
            <span className='col-1'> Pending : <span style={{color: 'red'}}>{totalPending}</span></span>
            </div>
            <div className='row col-7 d-flex justify-content-center'>
            <Link ref={addButtonRef} to="../outworddetails" className="btn2 col-2"  > <i className="fa fa-plus" aria-hidden="true"></i>Add</Link>
            <button className="btn2 col-2" onClick={EditDelivery}><i className="fa fa-edit"></i> Edit</button>
            <button className="btn2 col-2" onClick={handleTracking} ><i className="fa fa-map-marker"></i>ShipmentTrack</button>
             <button className="btn2 col-2" onClick={HandleDelete} ><i className="fa fa-trash"></i> Delete</button>
            {/* <button className="btn2 col-2" type="submit"> <i className="fa fa-sign-out" aria-hidden="true"></i> Close</button> */}
</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OutwardDetailsList